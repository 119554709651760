.not-bold-h3 {
  font-weight: normal;
}
.vertical-cards-container {
  width: 100%;
}

/* .vertical-cards-container .v-card-subgroup { */
  /* width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; */
/* } */

.green-bg-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('./green-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  text-align: center;
  z-index: 10;
  width: 100%;
  padding: 0 20px;
}

.regulations,
.shadowed-regulations {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}

.regulations h2,
.shadowed-regulations h2 {
  text-align: center !important;
}

.regulations div {
  text-align: center !important;
}

.regulations a {
  align-self: unset !important;
}

.regulation-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.regulation-section.first-info {
  margin-top: 20px;
}

.regulation-section .checked-info {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.regulation-section .checked-item {
  width: unset;
  align-self: flex-start;
  margin-left: 18px !important;
}

.regulations-desktop-img {
  display: block;
  width: 200%;
  margin-left: -100%;
  margin-top: -50%;
}

.regulation-section .checked-item-text {
  margin-top: -5px;
}

@media screen and (min-width: 768px) {
  .vertical-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .vertical-cards-container .v-card-subgroup {
    /* display: flex;
        flex-direction: row;
        justify-content: center; */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 90%;
    display: grid;
    margin: 0 auto;
    gap: 1rem;
  }

  .regulation-section .bicolor-titled-container {
    max-width: 80%;
  }
  .regulation-section .bicolor-titled-container div span {
    display: none;
  }

  .regulation-section .checked-item {
    align-self: unset;
  }

  .regulation-section a {
    align-self: center !important;
  }

  .regulations-desktop-img {
    display: block;
    width: 90%;
    height: 30%;
    margin-top: -200px;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .regulation-section {
    display: flex;
    flex-direction: row-reverse;
    max-width: unset !important;
    justify-content: space-between;
  }

  .regulations-desktop-img {
    display: block;
    width: 50%;
    height: 80%;
  }

  .regulation-section .checked-info {
    align-items: unset;
    width: unset;
    margin-left: 30px;
  }

  .regulation-section .checked-info a {
    align-self: flex-start !important;
  }

  .vertical-cards-container .v-card-subgroup {
    width: 70%;
  }
}
