.toggle-menu-btn {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .toggle-menu-btn {
    display: none;
  }
}
