.checked-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px;
  align-items: center !important;
}

.checked-item-text {
  text-align: left;
  margin-left: 30px;
  /* margin-top: 4px; */
  max-width: 400px;
}

.checked-item-icon {
  /* position: absolute; */
  height: 18px;
  width: 18px;
}

.checked-item-icon-container {
  width: 51px;
  height: 51px;
  background: var(--unnamed-color-21ce99) 0% 0% no-repeat padding-box;
  background: #1BA77D 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checked-sign-container {
  width: 18px;
  height: 18px;
  background: #1BA77D 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  align-self: flex-start;
  opacity: 10;
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
}

.checked-item-icon-container .checked-item-icon {
  align-self: center;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .checked-item {
    margin-left: 20px;
  }
}
