.top-divider {
  position: absolute;
  height: 55px;
  width: 100%;
  top: -1px;
  background-size: auto 55px;
  transform: rotate(180deg);
}

.bottom-divider {
  position: absolute;
  height: 55px;
  width: 100%;
  bottom: -1px;
  background-size: auto 55px;
}
