.sort-dropdown {
}

.sort-dropdown label {
  color: white !important;
}
.sort-dropdown select {
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}
