.pricing-toggle {
  display: flex;
  justify-self: center;
  align-self: center;
  position: relative;
  width: 300px;
  margin-bottom: 0.3rem;
}

.pricing-toggle-container {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
  border: 2px solid #000000;
  border-radius: 5rem;
  overflow: hidden;
  width: 300px;
  height: 40px;
  z-index: 0;
}

.toggle-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  /* padding: 0.50rem 1rem; */
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  font-size: 1rem;
  cursor: pointer;
}

.toggle-active {
  background-color: #0d9e70de !important;
  border-radius: 5rem;
  margin: 0.2rem;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1rem;
}

.toggle-tab:focus {
  outline: 2px solid #ffffff;
  box-shadow: 0 0 0 3px #000000;
  /* outline-offset: inherit !important; */
  /* outline: 5px solid yellow !important; */
}

.badge {
  position: absolute;
  right: 8px;
  top: 16px;
  background-color: #000000;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  z-index: 20;
}
