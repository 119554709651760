.custom-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 380px;
  height: fit-content;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.312rem;
  box-shadow: 0 20px 33px 0 rgba(0, 0, 0, 0.3);
}

.custom-horizontal-card {
  margin-bottom: 2rem;
  box-shadow: none;
  height: auto;
}

.shadow {
  box-shadow: 0 20px 33px 0 rgba(0, 0, 0, 0.3);
}

.custom-card-text-container {
  display: flex;
  flex-direction: column;
}

.custom-card-ellipse {
  position: relative;
  width: 301px;
  height: 160px;
  margin: 2rem auto;
}

.custom-sized-image .custom-card-ellipse {
  width: 287px;
  height: 150px;
  padding: 0.4rem;
}

.icon-container {
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.15);
  top: -20px;
  left: -30px;
}

.custom-card-icon {
  display: block;
  width: 100%;
  height: 100%;
}

.card-ellipse-bar {
  width: 165px;
  height: 165px;
  margin-top: 1rem;
}

.custom-card-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  display: block;
}

.alliBot-features .imgDiv-mac {
  width: 384px;
}

.imgDiv-mac {
  position: absolute;
  width: 386px;
  height: 216px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgDiv-browser {
  position: absolute;
  width: 362px;
  height: 220px;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.imgDivPosition {
  position: inherit;
}

.image-increase-height img {
  height: 111%;
}

/* =============== @media (min-width: 768px) =============== */
@media screen and (min-width: 768px) {
  .custom-horizontal-card {
    flex-direction: row;
    height: 224px;
    max-width: 960px;
    margin-bottom: 2rem;
  }

  .text-container-horizontal {
    justify-content: center;
    width: 70%;
    text-align: left;
  }
}

/* =============== @media (min-width: 872px) =============== */
@media screen and (max-width: 872px) {
  .alliBot-features-content .custom-card,
  .alliWidget-features .custom-card {
    margin-top: 2rem !important;
  }
}

/* =============== @media (min-width: 872.99px) =============== */
@media screen and (min-width: 872.99px) {
  .shadow-none {
    box-shadow: none;
  }
}
