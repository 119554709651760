#nav-language {
  display: flex;
  column-gap: 5px;
  justify-content: space-between;
  justify-items: center;
  margin-right: 10px;
  cursor: pointer;
}
#language-text {
  margin: auto;
  cursor: pointer;
}
.lang-opt {
  list-style: none;
  display: flex;
  column-gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px !important;
  cursor: pointer;
}
#lang-menu {
  padding-left: 20px;
}

@media (min-width: 1200px) {
  #lang-menu {
    position: absolute;
    top: 55px;
    right: 25px;
  }
  .lang-opt {
    margin-top: 0px;
    margin-bottom: 5px !important;
  }
}
