/* .page-top {
  margin-top: 135px;
} */

.privacy-policy-presentation-container {
  width: 100%;
  height: 100%;
  padding-bottom: 12.2rem;
  position: relative;
  background-color: #21ce99;
  text-align: start;
}

.privacy-policy-page-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  font: normal normal normal 16px/24px Archivo;
}

.privacy-policy-pill-position {
  top: -25px;
  left: 150px;
}

.privacy-policy-container {
  display: flex;
  justify-content: center;
  background-color: black;
}

.privacy-policy {
  text-align: left;
  padding: 36px;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 60%;
  color: white !important;
  margin-bottom: 50px;
}
.privacy-policy h5 {
  color: white !important;
}

.privacy-policy span {
  color: white !important;
}

.privacy-policy a {
  color: white !important;
}

.privacy-policy p {
  color: white;
}

.privacy-policy-ilustration-container {
  padding-top: 50px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .privacy-policy-pill-position {
    top: -30px;
    left: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .privacy-policy-pill-position {
    /* top: 250px;
    left: 1150px; */
    display: none;
  }

  .privacy-policy-presentation-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    column-gap: 99px;
    width: 100%;
  }

  .privacy-policy-page-container {
    margin-top: 0px;
  }

  .privacy-policy-presentation-sign {
    align-items: flex-start;
  }

  .privacy-policy-presentation-sign h1 {
    text-align: left;
  }
}

/* The style using in content for the privacy policy  */
[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: white !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: white !important;
  font-size: 14px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: white !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: white !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: white !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
  color: white !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

/*End style content privacy policy*/
