.bicolor-titled-container {
  text-align: left;
  padding: 20px;
  z-index: 10;
}

.bicolor-titled-container h2 {
  font: normal normal 900 32px/40px Archivo;
}

.bicolor-titled-container p {
  font: normal normal normal 16px/24px Archivo;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .bicolor-titled-container {
    max-width: 400px;
  }
  .bicolor-titled-container h2 {
    font: normal normal 900 40px/44px Archivo;
  }

  .bicolor-titled-container p {
    max-width: 400px;
    font: normal normal normal 16px/24px Archivo;
  }
}
@media screen and (min-width: 1200px) {
  .bicolor-titled-container {
    max-width: 532px;
  }
  .bicolor-titled-container h2 {
    font: normal normal 900 45px/50px Archivo;
    /* line-height: 80px; */
  }

  .bicolor-titled-container p {
    max-width: 500px;
    font: normal normal normal 18px/24px Archivo;
  }

  .a11y-first-bicolored div {
    display: none;
  }
}
