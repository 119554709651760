.a11y-navbar {
  top: 0px;
  left: 0;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 16px; */
  background: #21ce99;
}
.a11y-navbar-logo {
  margin-left: 20px;
  z-index: 2;
}
.a11y-navbar-logo a {
  display: block;
  padding: 0.1rem;
}

.a11y-navbar-logo button {
  cursor: pointer;
}

.a11y-navbar-logo-img {
  display: block;
}

.a11y-navbar-toggle {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  z-index: 2;
}

.a11y-navbar-items {
  display: none;
  align-items: center;
  column-gap: 25px;
  list-style-type: none !important;
}
.a11y-navbar-items a {
  text-decoration: none;
  color: black;
  padding: 10px;
  font: normal normal 20px/32px Archivo;
}
.a11y-navbar-items a:hover {
  background-color: black;
  color: white;
  border-radius: 20px;
}
.a11y-navbar-contact {
  display: none;
  margin-right: 20px;
}

.a11y-navbar-items a.link {
  text-decoration: none;
  color: white;
  padding: 0;
  font-size: 18px;
}


@media (min-width: 768px) and (max-width: 1200px) {
  .a11y-navbar-contact {
    display: flex;
    font-size: 20px;
  }
  .a11y-navbar-toggle {
    /* margin-right: 70px; */
  }
}

@media (min-width: 1200px) {
  .a11y-navbar-items {
    display: flex;
  }
  .a11y-navbar-items a {
    text-decoration: none;
    color: black;
    padding: 10px;
    font: normal normal 20px/32px Archivo;
  }
  .a11y-navbar-items a:hover {
    background-color: black;
    color: white;
    border-radius: 30px;
  }

  .a11y-navbar-contact {
    width: 161px !important;
    height: 45px !important;
    display: flex;
    /* margin-right: 300px; */
    /* margin-top: 10px; */
  }
  .a11y-navbar-logo-img {
    width: 80px !important;
    height: 28px !important;
  }

  .a11y-navbar-toggle {
    margin-right: 0;
  }
}

.toggle-menu-btn {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .toggle-menu-btn {
    display: none;
  }
}