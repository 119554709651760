.read-blog-page {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}
.read-blog-content {
  text-align: start;
  width: 60% !important;
}
.read-blog-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font: inherit;
}
.article-img-preview {
  width: 100%;
  height: 500px !important;
  border-radius: 10px;
  margin-top: 50px;
}
.article-img-preview img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
