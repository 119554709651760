.reviewCard {
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    flex: 0 1 calc(50% - 50px);
}

.reviewCard-image {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}

.reviewCard-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.reviewCard-content {
    text-align: left;
}

.reviewCard-name {
    font-weight: bold;
    margin-bottom: 5px;
}

.reviewCard-comment {
    font-size: 14px;
    color: #000000;
}

@media (max-width: 768px) {
    .reviewCard {
        flex: 0 1 100%;
    }
}