.tryItNow {
  width: 100%;
  background-color: #21ce99;
  position: relative;
}

.tryItNow-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 1020px;
  margin: 4rem auto;
  padding: 3rem 0;
}

.tryItNow-text h2 {
  font: normal normal 900 32px/40px Archivo;
}

/* =============== @media (min-width: 768px) and (max-width: 1200px) =============== */
@media (min-width: 768px) and (max-width: 1200px) {
  .tryItNow-content {
    flex-direction: row;
  }

  .tryItNow-text h2 {
    font: normal normal 900 40px/44px Archivo;
    margin: 0.22em 0;
  }
}

/* =============== @media (min-width: 1200px) =============== */
@media screen and (min-width: 1200px) {
  .tryItNow-content {
    flex-direction: row;
  }

  .tryItNow-text h2 {
    font: normal normal 900 45px/50px Archivo;
    margin: 0.22em 0;
  }

  .tryItNow-content {
    text-align: left;
  }
}
