/* .page-top {
  margin-top: 135px;
} */

.privacy-policy-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font: normal normal normal 16px/24px Archivo;
}

.privacy-policy-pill-position {
  top: -25px;
  left: 150px;
}

.privacy-policy-statement {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: black;
  color: white;
}

.privacy-policy-statement h3 {
  margin-bottom: 0;
}

.privacy-policy-statement span {
  color: #595959;
}
.privacy-policy-statement a {
  color: white !important;
}
.privacy-policy-statement p {
  color: white;
  margin-top: 0;
}

.privacy-policy-ilustration-container {
  padding-top: 50px;
  padding-right: 80px;
}

.privacy-policy-content {
  width: 60%;
  margin-top: 66px;
  margin-bottom: 66px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .privacy-policy-pill-position {
    top: -30px;
    left: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .privacy-policy-pill-position {
    /* top: 250px;
    left: 1150px; */
    display: none;
  }
  .privacy-policy-presentation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 99px;
    padding-bottom: 6rem;
  }
  .privacy-policy-page-container {
    margin-top: 0px;
  }
  .privacy-policy-presentation-sign {
    align-items: flex-start;
  }
  .privacy-policy-presentation-sign h1 {
    text-align: left;
  }
}

.contact-info {
  margin-bottom: 0;
}

.contact-info:last-child {
  margin: 0;
}
