.home-ilustration-container {
  width: 350px;
  height: 520px;
}
.home-ilustration {
  width: 343px;
  height: 343px;
}

.home-ilustration-small-circle {
  position: absolute;
  top: -20px;
  left: 40px;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFFFFF' stroke-width='15' stroke-dasharray='4' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.home-ilustration-large-circle {
  position: absolute;
  top: 295px;
  left: -35px;
  width: 260px;
  height: 270px;
  border-radius: 50%;
  z-index: 1;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='150' ry='150' stroke='%23FFFFFF' stroke-width='20' stroke-dasharray='4.5' stroke-dashoffset='10' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.home-ilustration-functionality-pill {
  top: -40px;
  left: 170px;
}
.home-ilustration-accessibility-pill {
  top: 220px;
  right: 170px;
}
.home-ilustration-automation-pill {
  top: 320px;
  left: 170px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .home-ilustration-container {
    width: 110%;
    height: 650px;
    overflow-y: hidden;
    margin-left: 50px;
    overflow-x: hidden;
  }
  .home-ilustration {
    height: 509px;
    width: 509px;
    z-index: 2;
  }
  .home-ilustration-small-circle {
    top: 40px;
    left: 60px;
  }
  .home-ilustration-large-circle {
    top: 370px;
    left: 410px;
    z-index: 1;
  }
  .home-ilustration-functionality-pill {
    top: -20px;
    left: 320px;
  }
  .home-ilustration-accessibility-pill {
    top: 270px;
    left: 0px;
  }
  .home-ilustration-automation-pill {
    top: 465px;
    left: 350px;
  }
}

@media screen and (min-width: 1200px) {
  .home-ilustration-container {
    width: 390px;
    height: 490px;
    overflow-x: visible;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-ilustration {
    height: 400px;
    width: 400px;
  }
  .home-ilustration-functionality-pill {
    position: absolute;
    top: 190px;
    left: 250px;
  }
  .home-ilustration-accessibility-pill {
    top: 260px;
    left: -70px;
  }
  .home-ilustration-automation-pill {
    top: 355px;
    left: 20px;
  }
  .home-ilustration-small-circle {
    top: 10px;
    left: 20px;
  }
  .home-ilustration-large-circle {
    width: 160px;
    height: 170px;
    top: 280px;
    left: 260px;
    z-index: 1;
  }
}
