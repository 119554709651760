.search-bar {
  position: relative;
}
.search-bar input {
  width: 100%;
  padding: 10px;
  padding-left: 35px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: black;
  color: white;
}
.search-bar svg {
  /* content: "\1F50D"; Unicode for a magnifying glass icon */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: gray;
}

.search-bar input::placeholder {
  color: white;
}

@media screen and (min-width: 768px) {
  .search-bar {
    margin-left: auto;
  }
}
