.card-container {
  /* padding: 0.2rem; */
  width: 100%;
  position: relative;
  transition: transform 0.3s ease;
  padding-bottom: 1rem;
}

.card {
  height: 100%;
  border-radius: 0.5rem;
  border: 2px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: rgba(128, 128, 128, 0.048);
  cursor: pointer;
}

.card:hover {
  border: 2px solid #000000 !important;
}

.card-popular {
  border: 2px solid #0dfdb1 !important;
  box-shadow: 0 4px 8px #1fd59b98;
}

.card-popular-container {
  position: absolute;
  top: -7px;
  right: 20px;
  height: 30px;
  width: 100px;
  color: white;
  font-size: 1.0rem;
  background-color: black;
  border-radius: 18px;
  z-index: 100;
  text-align: center;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.card-popular-container>p {
  text-align: center;
  line-height: 0rem !important;
}

.card-title {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  margin-top: 2.0rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.93);
  line-height: 0.2 !important;
}

.card-price {
  font-size: 1.7rem;
  color: #0D9E71;
  padding-bottom: 1.5rem;
  margin-bottom: 0.1rem;
  line-height: 0.2 !important;
  min-height: 15px;
}

.card-description {
  font-size: 1rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.93);
  min-height: 35px;
  height: 35px;
}

.feature-pricing-container {
  padding-top: 9px;
  list-style-type: none;
  display: none;
  padding-inline-start: 0px !important;
}

.feature-pricing-container li {
  border: 1px solid rgba(0, 0, 0, 0);
}

.feature-pricing {
  height: 68px;
  min-height: 68px;
  max-height: 68px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-feature {
  display: flex;
  align-items: center;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.feature-pricing-mobile {
  display: none;
}

.feature-plan-mobile {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  max-height: 40px;
  min-height: 35px;
}

.feature-pricing-mobile-title {
  font-weight: 700;
  font-size: 1.5rem;
  width: 100%;
  text-align: start;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #0000000e;
  height: 40px;
}

.feature-pricing-mobile-description {
  font-weight: 500;
  font-size: 1.0rem;
  line-height: inherit !important;
  width: 100%;
  text-align: start !important;
}

@media (max-width: 1300px) {
  .feature-pricing-mobile {
    display: block;
  }
}

@media (max-width: 1000px) {
  .card-description {
    min-height: 30px;
    height: 30px;
  }

  .card-title {
    line-height: 1.5 !important;
    height: 30px;
  }
}