.illustration-container {
  align-self: center;
  margin-top: 90px;
}

.illustration-main {
  width: 350px;
  height: 520px;
  /* overflow-y: hidden; */
}

.illustration {
  width: 343px;
  height: 455px;
  max-width: 99%;
}

.illustration-small-circle {
  position: absolute;
  top: 230px;
  left: 315px;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFFFFF' stroke-width='15' stroke-dasharray='4' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.illustration-large-circle {
  position: absolute;
  top: -170px;
  left: -185px;
  width: 260px;
  height: 270px;
  border-radius: 50%;
  z-index: 1;
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='150' ry='150' stroke='%23FFFFFF' stroke-width='20' stroke-dasharray='4.5' stroke-dashoffset='10' stroke-linecap='butt'/%3e%3c/svg%3e");
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .illustration-main {
    width: 100%;
    height: 520px;
  }
  .illustration {
    width: 660px;
    height: 500px;
  }

  .illustration-small-circle {
    position: absolute;
    top: 200px;
    left: 600px;
    width: 120px;
    height: 120px;
  }

  .illustration-large-circle {
    position: absolute;
    top: 100px;
    left: -230px;
    width: 300px;
    height: 300px;
  }
  .illustration-container {
    margin-top: 300px;
  }
}

@media screen and (min-width: 1200px) {
  .illustration-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .illustration-main {
    width: 100%;
  }

  .illustration {
    width: 880px;
    height: 667px;
  }

  .illustration-small-circle {
    position: absolute;
    top: -60px;
    left: 1220px;
    width: 120px;
    height: 120px;
  }

  .illustration-large-circle {
    position: absolute;
    top: 250px;
    left: 100px;
    width: 200px;
    height: 200px;
  }
}
