.external-container {
  width: 90%;
  opacity: 1;
  margin: 0 5%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.tool-image {
  width: 25%;
  height: 23vw;
  margin: 20px 4.1%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px #AAAAAA1A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tool-image img{
  width: 70%;
  height: 70%;
}

.tool-image:nth-child(5n + 4) {
  margin-left: 20%;
}

.toolset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.toolset-container .bicolor-titled-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .tool-image {
    width: 15%;
    height: 14vw;
    margin: 20px 3% !important;
  }

  .tool-image:nth-child(5n + 4) {
    margin-left: 0;
  }

  .tool-image:nth-child(8n + 5) {
    margin-left: 15% !important;
  }
}

@media screen and (min-width: 1200px) {
  
  .tool-image:nth-child(5n + 4) {
    margin-left: 0;
  }
  
  .tool-image:nth-child(8n + 5) {
    margin-left: 0;
  }

  .tool-image {
    width: 10%;
    height: 8vw;
    margin: 20px 2% !important;
  }


  .tool-image:nth-child(14n + 8) {
    margin-left: 10% !important;
  }
}
