.side-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-out;
  background-color: white;
  z-index: 200;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(+100%);
}

.side-drawer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 36px !important;
  list-style-type: none;
  padding: 0 !important;
  font-size: larger;
}

.side-drawer-links li {
  margin-bottom: 24px;
}

.side-drawer-links a {
  text-decoration-line: underline;
  text-decoration-color: black;
  color: black;
}

.side-drawer-links a.link {
  text-decoration: none;
  color: white;
  padding: 0;
  font-size: 18px;
}

.side-drawer-button {
  margin: 36px 0 36px ;
}

/* @media (min-width: 770px) and (max-width: 1200px) { */
  /* .side-drawer-button {
    display: none;
  } */
/* } */
