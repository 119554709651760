.a11y-services {
}
.services-circles-section {
  position: relative;
}

.services-circle {
  position: absolute;
  width: 70px;
  height: 70px;
  top: -50px;
  left: -100px;
  border-radius: 50%;
  border: 40px solid #21ce99;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.services-functional-img {
  width: 36px;
  height: 46px;
}
.services-accessibility-img {
  width: 46px;
  height: 46px;
}
.services-automation-img {
  width: 64px;
  height: 49px;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .services-circle {
    left: auto;
    top: -20px;
    right: -200px;
    width: 119px;
    height: 119px;
    border: 56px solid #21ce99;
  }
  .services-accessibility-img {
    width: 67px;
    height: 67px;
  }
  .services-automation-img {
    width: 94px;
    height: 71px;
  }
}
@media (min-width: 1200px) {
  .a11y-services {
    display: flex;
    column-gap: 70px;
    align-items: flex-end;
  }

  .services-circle {
    left: auto;
    right: -300px;
    width: 119px;
    height: 119px;
    border: 56px solid #21ce99;
  }
  .services-functional-img {
    width: 50px;
    height: 63px;
  }
  .services-accessibility-img {
    width: 67px;
    height: 67px;
  }
  .services-automation-img {
    width: 94px;
    height: 71px;
  }
}
