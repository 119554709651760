.achievement-card {
  min-height: 123px;
  width: 343px;
  max-width: 95%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  align-items: left;
  box-shadow: 0px 3px 40px #aaaaaa19;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 18px;
}

.achievement-card-ellipse {
  width: 60px;
  height: 60px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 15px;
}

.achievement-card-text-container {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  color: #000000;
}

.achievement-card-img {
  height: 32.34px;
  width: 32.34px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .achievement-card {
    height: 320px;
    width: 234px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .achievement-card-ellipse {
    height: 93px;
    width: 95px;
  }
  .achievement-card-img {
    height: 52px;
    width: 52px;
  }
  .achievement-card-text-container {
    text-align: center;
    color: #000000;
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-card {
    width: 299px;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .achievement-card-ellipse {
    height: 120px;
    width: 120px;
  }
  .achievement-card-img {
    height: 66px;
    width: 66px;
  }
  .achievement-card-text-container {
    text-align: center;
    color: #000000;
  }
}
