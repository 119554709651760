#sitemap-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background-color: black;
  color: white;
  z-index: 2;
}

#sitemap-container h1 {
  align-self: center;
}

.sitemap-listing {
  font-size: larger;
  color: black;
  z-index: 10;
}

.sitemap-listing ul {
  text-align: left;
}

.sitemap-listing ul ul {
  list-style-type: circle;
}

.sitemap-listing li {
  margin: 30px 0;
}

.sitemap-listing a {
  color: white;
  text-decoration: underline;
}

.openIcon {
  vertical-align: middle;
  margin-left: 4px;
}

.sitemap-content {
  width: 60%;
}

@media (min-width: 1200px) {
  .sitemap-listing {
    font-size: larger;
    margin-left: 70px;
    color: white;
  }
}
