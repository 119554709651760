.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: start;
}

.dialog-footer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.close-button {
  float: right;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
}