.article-container {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  text-align: start;
  cursor: pointer;
  z-index: 2;
  border: 3px solid transparent;
}

.article-container:hover {
  border: 3px solid #21ce99;
}

.grid-container > div {
  padding: 10px;
  text-align: center;
}
.article-img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.article-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.a11y-home-presentation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 99px;
  margin-top: 0px;
}
.title-container {
  height: 60px;
  text-overflow: ellipsis;
}
.author-readtime {
  display: none;
}
.preview-content-container {
  margin: 0;
}
.preview-info {
  display: flex;
}

@media screen and (min-width: 768px) {
  .article-container {
    background-color: #f5f5f5;
    color: black;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    border: 3px solid #000;
    text-align: start;
    cursor: pointer;
    z-index: 2;
  }

  .author-readtime {
    margin-top: 30px;
    margin-bottom: 0;
    display: inherit;
  }
  .info-author-readtime {
    display: none;
  }
  .brief-preview {
    display: none;
  }
}
