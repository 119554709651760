.layout-content.layout-alliBot {
  padding: 0;
  background: none !important;
}

/* =============== alliBot-presentation-container =============== */
.alliBot-page-container .home-ilustration-container > div {
  width: fit-content;
  margin: 50px auto 0;
}

.alliBot-presentation-sign text:last-of-type {
  margin-top: 1rem;
}

.alliBot-presentation-sign {
  margin: 0 7%;
}

.alliBot-presentation-container {
  background: #21ce99;
  position: relative;
}

.alliBot-presentation-container .privacy-policy-ilustration-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px;
  z-index: 2;
}

.alliBot-presentation-container .home-ilustration-container {
  width: 85%;
  height: 400px;
  margin: 0 5%;
}

.alliBot-page-container .home-ilustration {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  display: block;
}

/* =============== alliBot-content =============== */
.alliBot-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
}

/* =============== alliBot-accessibility =============== */
.alliBot-accessibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 6rem 2rem 7rem;
  background-color: #000;
  color: #fff;
}

.alliBot-accessibility-content,
.alliBot-why-choose-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 2rem;
  /* max-width: 880px; */
  /* margin-top: 1.5rem; */
}

.accessibility-item,
.alliBot-accessibility-content {
  text-align: left;
}

.accessibility-item h3,
.alliBot-accessibility-content h3 {
  margin-bottom: 0.5rem;
}

.accessibility-item p,
.alliBot-accessibility-content p {
  margin-top: 0;
}

/* =============== alliBot-features =============== */
.alliBot-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1020px;
  padding: 0 1rem 3rem;
}

.alliBot-features-content {
  display: flex;
  flex-wrap: wrap;
}

.alliBot-features-content > :last-child {
  margin-bottom: 3rem;
}

/* =============== alliBot-why-choose =============== */
.alliBot-why-choose {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 5rem 2rem 3rem;
  background-color: #000;
  color: #fff;
}

/* .alliBot-why-choose-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 2rem;
  margin-top: 1.5rem;
} */

.why-choose-item,
.alliBot-why-choose-content {
  text-align: left;
}

.why-choose-item h3,
.alliBot-why-choose-content h3 {
  margin-bottom: 0.5rem;
}

.why-choose-item p,
.alliBot-why-choose-content p {
  margin-top: 0;
}

.custom-why-choose-button{
  width: 280px;
}
/* =============== alliBot-review =============== */
.alliBot-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.alliBot-review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  max-width: 1020px;
  padding: 0 1rem;
}

/* =============== @media (max-width: 767px) =============== */
@media screen and (max-width: 767px) {
  /* .alliBot-feedback .bicolor-titled-container div, */
  .alliBot-features .bicolor-titled-container,
  .alliBot-accessibility .bicolor-titled-container,
  .alliBot-why-choose .bicolor-titled-container {
    max-width: 500px;
    text-align: center;
  }
}

/* =============== @media (min-width: 768px) and (max-width: 1200px) =============== */
@media (min-width: 768px) and (max-width: 1200px) {
  .alliBot-presentation-container .home-ilustration-container {
    height: 380px;
  }

  .alliBot-presentation-container .home-ilustration-container {
    width: 508px;
  }

  .alliBot-presentation-container .page-top {
    width: 60%;
    margin: auto;
  }

  .alliBot-presentation-container .privacy-policy-ilustration-container {
    width: 71%;
    margin: auto;
  }

  .alliBot-content .bicolor-titled-container,
  .alliBot-features .bicolor-titled-container {
    /* max-width: 70%; */
    max-width: 100%;
  }

  .alliBot-content .bicolor-titled-container p {
    font: normal normal normal 24px/30px Archivo;
  }

  /* .alliBot-features-content > :nth-child(1),
  .alliBot-content .home-services-bicolored-text,
  .standalone-card,
  .alliBot-accessibility {
    z-index: 2;
  } */

  .alliBot-features-content > :nth-child(odd) {
    margin-top: 4rem;
  }

  .alliBot-features-content > :nth-child(even) {
    margin-top: 6rem;
  }

  .alliBot-features-content > :nth-child(4) {
    margin-top: 4rem;
  }

  .alliBot-features-content > :last-child {
    margin-bottom: 5rem;
  }

  .standalone-card {
    margin-top: 1rem;
  }

  .alliBot-accessibility-content,
  .alliBot-why-choose-content {
    grid-template-columns: repeat(2, 1fr);
  }
  /* .alliBot-feedback-text {
    text-align: left;
    font: normal normal normal 22px/32px Archivo;
  } */

  /* .privacy-statement {
    text-align: left;
  } */
}

/* =============== @media (min-width: 1200px) =============== */
@media screen and (min-width: 1200px) {
  .alliBot-page-container .home-ilustration-container > div {
    width: 263px;
    height: 313px;
  }

  .alliBot-presentation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .alliBot-presentation-container .page-top {
    width: 50%;
    margin: auto auto 7rem;
  }

  .alliBot-presentation-container .privacy-policy-ilustration-container {
    width: 40%;
    margin: auto auto auto 0;
  }

  .alliBot-presentation-container .home-ilustration-container {
    width: 100%;
    height: 499px;
  }

  .alliBot-presentation-sign,
  .alliBot-presentation-sign h1 {
    text-align: left;
    align-items: start;
  }

  .alliBot-content {
    margin: auto;
    /* padding-top: 2rem; */
  }

  .alliBot-features {
    padding-top: 3rem;
  }

  .alliBot-content .bicolor-titled-container p {
    font: normal normal normal 24px/30px Archivo;
  }

  .alliBot-features-content {
    margin-top: 1rem;
  }

  /* .alliBot-features-content > :nth-child(1),
  .alliBot-content .home-services-bicolored-text,
  .standalone-card,
  .alliBot-accessibility {
    z-index: 2;
  } */

  .alliBot-features-content > :nth-child(odd) {
    margin-top: 4rem;
  }

  .alliBot-features-content > :nth-child(even) {
    margin-top: 6rem;
  }

  .alliBot-features-content > :nth-child(4) {
    margin-top: 4rem;
  }

  .alliBot-features-content > :last-child {
    margin-bottom: 5rem;
  }

  .standalone-card {
    margin-top: 3rem;
  }

  /* .alliBot-feedback .bicolor-titled-container, */
  .alliBot-features .bicolor-titled-container {
    max-width: 750px;
  }

  .alliBot-accessibility-content {
    grid-template-columns: repeat(2, 1fr);
  }

  .alliBot-why-choose-content {
    grid-template-columns: repeat(3, 1fr);
  }

  /* .alliBot-feedback .bicolor-titled-container p {
    max-width: 750px;
  } */

  /* .alliBot-feedback-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1020px;
    gap: 2rem;
  } */

  /* .alliBot-feedback-text {
    text-align: left;
    font: normal normal normal 22px/32px Archivo;
  } */

  /* .privacy-statement {
    max-width: 882px;
    text-align: left;
  } */
}
