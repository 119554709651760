.skip__link {
  position: absolute;
  top: -3.125rem;
  left: 7%;
  padding: 0.8rem;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  background-color: #000;
  border-radius: 5px;
  font-family: Archivo;
  font-size: 18px;
  opacity: 0;
  transition: top 0.35s ease, opacity 0.35s ease;
  z-index: 100000 !important;
}

.skip__link:focus-within {
  opacity: 1;
  top: 1rem;
  left: 7%;
}

.skip__link a {
  padding: 0.3rem;
  color: #fff;
  text-decoration: none;
}

.skip__link a:hover,
.skip__link a:focus {
  outline: 3px solid black;
  outline-offset: 1px;
  border-radius: 1px;
  box-shadow: 0 0 0 2px white;
}

.separator {
  color: #fff;
  margin: 0 0.3rem;
}
