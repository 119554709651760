.a11y-qualities {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 0px;
  padding-bottom: 100px;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: black;
}

.a11y-qualities p {
  font: normal normal normal 16px/24px Archivo;
  color: white !important;
}

.a11y-qualities h3 {
  font: normal normal bold 18px/32px Archivo;
}

.a11y-qualities-top-section {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  z-index: 2;
}

.a11y-qualities-section {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  z-index: 2;
}

.why-choose-us-qualities {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.home-why-choose-us {
  text-align: left !important;
  z-index: 2;
}

.qualities-circles-container {
  position: relative;
  z-index: 1;
}

.qualities-right-circle {
  position: absolute;
  top: 30px;
  right: -100px;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 56px solid #21ce99;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.qualities-left-circle {
  position: absolute;
  width: 250px;
  height: 250px;
  top: 300px;
  left: -120px;
  border-radius: 50%;

  border: 56px solid #27dba4bf;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.qualities-quality-card,
.qualities-experience-card {
  background-color: rgb(0, 0, 0) !important;

  color: white !important;
}

.qualities-experience-card {
  box-shadow: 0px 3px 10px #fafbfb1d !important;
}

.qualities-quality-card h3,
.qualities-experience-card h3 {
  color: white
}

@media (min-width: 768px) and (max-width: 1200px) {
  .a11y-qualities-section {
    flex-direction: row;
    column-gap: 32px;
  }

  .why-choose-us-qualities {
    width: 608px;
  }

  .a11y-qualities-top-section {
    flex-direction: row-reverse;
    column-gap: 32px;
  }

  .qualities-quality-card {
    margin-bottom: 120px;
    background-color: black !important;
  }

  .qualities-experience-card {
    align-self: flex-end;
  }

  .qualities-left-circle {
    top: 300px;
    left: -182px;
    width: 227px;
    height: 227px;
    border: 56px solid #27dba4bf;
  }

  .qualities-right-circle {
    top: 250px;
    right: -70px;
  }

  .a11y-qualities {
    row-gap: 0px;
    margin-top: 90px;
  }
}

@media (min-width: 1200px) {
  .a11y-qualities {
    row-gap: 0px;
    margin-top: 80px;
  }

  .a11y-qualities-section {
    display: flex !important;
    flex-direction: row;
    column-gap: 32px;
    margin-top: 29px;
    justify-content: center;
  }

  .a11y-qualities-top-section {
    flex-direction: row;
    column-gap: 32px;
  }

  .why-choose-us-qualities {
    display: flex;
    flex-direction: row;
    padding-top: 100px;
  }

  .why-choose-us-qualities {
    text-align: left !important;
  }

  .qualities-left-circle {
    top: 280px;
    left: -400px;
    width: 227px;
    height: 227px;
    border: 56px solid #27dba4bf;
  }

  .qualities-right-circle {
    /* top: 100px;
    right: -870px; */
    display: none;
  }
}