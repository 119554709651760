.a11y-button {
  border: none;
  border-radius: 26px;
  height: 43px;
  padding: 0%;
  font-size: 18px;
  cursor: pointer;
  font-family: Archivo;
}

/* .a11y-button:focus {
  outline: 5px solid yellow;
} */

.a11y-button-large {
  width: 246px;
}
.a11y-button-medium {
  width: 162px;
}
.a11y-button-dark {
  background-color: #000000;
  color: white;
}
.a11y-button-dark:hover {
  color: #21ce99 !important;
}
.a11y-button-light {
  background-color: #21ce99;
  color: black;
}
.a11y-button-negative {
  background-color: white;
  color: black;
  border: 2px solid #000000;
  font-weight: 550;
}
.a11y-button-negative:hover {
  outline: #0d9e71 3px solid;
}

/* .button-light-background:focus {
  outline: 3px solid #000000;
  box-shadow: 0 0 0 2px #ffffff;
} */

.button-dark-background:focus {
  outline: 2px solid #ffffff;
  box-shadow: 0 0 0 3px #000000;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .a11y-button {
    height: 51px;
  }
}
