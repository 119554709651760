.presentation-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
.presentation-sign b {
  font: normal normal 900 48px/50px Archivo;
  z-index: 2;
}
.presentation-sign p {
  font: normal normal 18px/24px Archivo;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .presentation-sign b {
    font: normal normal 900 64px/60px Archivo;
  }
  .presentation-sign p {
    font: normal normal 24px/32px Archivo;
  }
}
@media (min-width: 1200px) {
  .presentation-sign {
    align-items: flex-start;
  }

  .presentation-sign p {
    text-align: start;
    font: normal normal 24px/32px Archivo;
    z-index: 2;
  }
  .presentation-sign b {
    text-align: start;
    z-index: 2;
    font: normal normal 900 64px/72px Archivo;
  }
  .presentation-sign h1 {
    z-index: 2;
    text-align: left;
  }
}
