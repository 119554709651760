.layout-content {
  padding: 0;
  background: linear-gradient(#21ce99 1040px, white 20px);
  overflow-x: hidden !important;
  overflow-y: hidden;
}
.circles-container {
  position: relative;
}
.right-circle {
  top: -135px;
  right: -192px;
  width: 202px;
  height: 202px;
  border: 80px solid #ffffff61;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.bottom-circle {
  top: 930px;
  right: -190px;
  width: 191px;
  height: 191px;
  border: 32px solid #ffffff61;
  border-radius: 50%;
  position: absolute;
}
.left-circle {
}
@media (min-width: 768px) and (max-width: 1200px) {
  .layout-content {
    background: linear-gradient(#21ce99 1204px, white 20px);
  }
  .left-circle {
    position: absolute;
    top: -300px;
    left: -300px;
    width: 480px;
    height: 480px;
    border-radius: 50%;
    border: 80px solid #ffffff61;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 1;
  }
  .bottom-circle {
    top: 1100px;
    left: 79px;
    border: 40px solid #ffffff61;
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 1200px) {
  .layout-content {
    background: linear-gradient(#21ce99 650px, white 20px);
  }
  .bottom-circle {
    top: 1025px;
    left: 154px;
    width: 180px;
    height: 180px;
    border: 72px solid #ffffff61;
  }
  .left-circle {
    position: absolute;
    top: -300px;
    left: -300px;
    width: 480px;
    height: 480px;
    border-radius: 50%;
    border: 80px solid #ffffff61;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 1;
  }
}
