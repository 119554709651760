.a11y-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  /* width: 246px; */
  height: 43px;
  padding: 0;
  /* background-color: #000; */
  /* color: #fff; */
  text-decoration: none;
  border: none;
  border-radius: 26px;
  font-family: Archivo;
  font-size: 18px;
  cursor: pointer;
}

.a11y-link-extra {
  width: 300px;
}

.a11y-link-large {
  width: 246px;
}

.a11y-link-medium {
  width: 162px;
}

.a11y-link-dark {
  background-color: #000000;
  color: white;
}

.a11y-link-dark:hover {
  color: #21ce99 !important;
}

.a11y-link-light {
  background-color: #21ce99;
  color: black;
}

.a11y-link-negative {
  background-color: white;
  color: black;
  border: 2px solid #000000;
  font-weight: 550;
}

.a11y-link-negative:hover {
  outline: #0d9e71 3px solid;
}

.a11y-link-disabled {
  background-color: #d3d3d3;
  color: #000000;
  cursor: not-allowed;
}
.a11y-link-disabled:hover {
  background-color: #d3d3d3;
  color: #000000;
  cursor: not-allowed;
  outline: none;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .a11y-link {
    height: 51px;
  }
}
