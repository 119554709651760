.table-responsive {
  margin: 0 -15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.container-table-pricing {
  display: block;
  text-align: start;
  border-collapse: collapse;
  font-size: 16px;
  text-align: center;
  min-width: 768px !important;
  max-width: 1400px !important;
}

.table-button {
  display: flex;
  align-items: center;
  gap: .3rem;
  width: max-content !important;
  max-width: 246px !important;
  /* min-width: 150px !important; */
  margin: auto;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.table-button-mobile {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
}

.container-table-pricing table {
  min-width: 768px !important;
  max-width: 1400px !important;
}

.top-border-header {
  border-top: 3px solid #0d9e7000 !important;

}

.container-table-pricing tr:last-child td:nth-child(4) {
  border-bottom: 3px solid #0d9e7000 !important;
}

.columns-popular {
  border-right: 3px solid #ffffff00 !important;
  border-left: 3px solid #ffffff00 !important;
}

.container-table-pricing th:first-child,
.container-table-pricing td:first-child {
  text-align: start;
}

.container-table-pricing tr {
  border: 1px solid #cccccc00;
  width: 100%;
}

.container-table-pricing td {
  border: none;
  text-align: center;
  padding: 0.5rem;
  height: fit-content;
  width: 20%;
}

.container-table-pricing th {
  width: 20%;
}

.container-table-pricing .card-price {
  font-size: 1.7rem;
  color: #0D9E71;
  line-height: 0.2 !important;
  min-height: 15px;
}

.container-table-pricing .colspan-header {
  color: #0D9E71;
  padding: 1rem;
  min-height: 10px;
  height: 30px;
  font-weight: 600;
  font-size: 1.5rem;
  display: relative;
}

.container-table-pricing .th-header {
  color: #0D9E71;
  padding: 1rem;
  min-height: 10px;
  font-weight: 600;
  font-size: 1.5rem;
}


.list-pricing th {
  padding-left: 10px;
  max-width: 250px;
  width: 250px;
  min-width: 10px;
  font-size: 15px;
  font-weight: 500;
}

.list-pricing-heading {
  width: 100%;
  font-size: 19px;
  background-color: rgba(0, 0, 0, 0.014);
}

.list-pricing-heading td {
  padding-left: 10px;
  max-width: 250px;
  min-width: 200px;
  width: 250px;
  color: #0D9E71;
  font-weight: 600;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.021);
}

.pricing-popular-container {
  position: absolute;
  left: 6rem;
  top: -10px;
  height: 18px;
  width: 100px;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: black;
  border-radius: 18px;
  z-index: 100;
  text-align: center;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.container-mobile-pricing {
  display: none;
}

@media screen and (max-width: 1000px) {
  .container-table-pricing {
    display: none;
  }

  .table-responsive {
    margin: inherit;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    gap: 5px;
  }

  .container-mobile-pricing {
    display: block;
    width: 100%;
  }
}


@media screen and (min-width: 1100px) {
  .pricing-popular-container {
    left: 6rem;
    top: -10px;
  }
}



@media screen and (min-width: 1400px) {
  .table-responsive {
    align-items: center;
  }
  .pricing-popular-container {
    left: 7rem;
    top: -10px;
  }
}