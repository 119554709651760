.a11y-button.automation,
.a11y-link.automation {
  align-self: start;
  margin-top: 30px;
  margin-left: 20px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font: normal normal normal 16px/24px Archivo;
  margin-top: 130px;
}

.intermediate-image {
  display: flex;
  flex-direction: column;
  /* margin: 120px 0; */
}

.last-image {
  display: flex;
  flex-direction: column;
  margin: 120px 0 -90px 0;
}

.automation-first-encircled-img {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  border: 24px solid #21ce99;
  width: 287px;
  height: 287px;
  border-radius: 287px;
  z-index: 10;
}

.automation-first-image {
  width: 249px;
  height: 249px;
}

.automation-first-blurred-circle {
  width: 100px;
  height: 100px;
  border: 40px solid #27dba419;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 50%;
  margin-top: -100px;
}

.automation-second-image {
  width: 171.5px;
  height: 171.5px;
  align-self: flex-end;
  z-index: 10;
}

.automation-second-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.automation-fourth-image {
  width: 122px;
  height: 122px;
  margin-bottom: 10px;
}

.automation-second-circle-container {
  display: flex;
  margin-top: -300px;
}

.automation-second-circle {
  width: 45px;
  height: 45px;
  border: 32px solid #21ce99;
  border-radius: 287px;
  margin-top: 50px;
  z-index: 5;
}

.automation-second-blurred-circle {
  width: 95px;
  height: 95px;
  border: 40px solid #27dba419;
  border-radius: 50%;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  margin-left: -30px;
}

.automation-fith-image {
  width: 300px;
  height: 300px;
  align-self: flex-end;
  margin-bottom: 160px;
  margin-top: -150px;
  z-index: 10;
}

.automation-third-circle {
  width: 60px;
  height: 60px;
  border-top: 40px solid #21ce99;
  border-right: 40px solid #21ce99;
  border-bottom: 40px solid #21ce99;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;

  left: 30px;
  margin-left: -50px;
  margin-top: 295px;
  position: absolute;
}

.automation-third-blurred-circle {
  width: 114px;
  height: 114px;
  border: 56px solid #27dba419;
  border-radius: 50%;
  margin-top: -100px;
}

.automation-fourth-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 24px solid #21ce99;
  border-radius: 50%;
  z-index: 1;
  right: 10px;
  margin-top: 70px;
}

.info-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.checked-info {
  display: flex;
  flex-direction: column;
}

.shadowed-section {
  width: 100% !important;
  max-width: unset !important;
  padding: 20px;
  margin-top: 50px;
  background-color: #aaaaaa19;
}

.pill-position {
  top: -40px;
  left: 170px;
}

.second-section-items {
  margin-top: -10px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .automation-section {
    align-self: center;
  }

  .a11y-button.automation,
  .a11y-link.automation {
    align-self: start;
    margin-top: 30px;
  }

  .intermediate-image {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-right: 20px;
  }

  .automation-fith-image {
    width: 400px;
    height: 400px;
    align-self: center;
    margin-top: 30px;
    margin-left: 150px;
  }

  .automation-fourth-circle {
    right: 200px;
    margin-top: 230px;
  }

  .automation-third-blurred-circle {
    width: 170px;
    height: 170px;
    position: absolute;
    /* margin-left: 100px; */
    margin-top: 10px;
  }

  .automation-third-circle {
    left: 30px;
    margin-left: -50px;
    margin-top: 417px;
  }

  /* .info-with-image{
        align-self: flex-start;
    } */

  /* .checked-info, button.automation{
        align-self: flex-start;
    } */

  button.automation,
  .a11y-link.automation {
    margin-left: 20px !important;
  }

  /* .shadowed-section {
    align-self: unset !important;
    align-items: center;
  } */

  .second-info .intermediate-image {
    margin-left: 270px;
  }

  .pill-position {
    top: -40px;
    left: 470px;
  }

  .last-image {
    margin-left: 100px;
  }

  .second-section-items {
    margin-top: 0;
  }
}

@media screen and (min-width: 1200px) {
  .info-with-image {
    flex-direction: row;
  }

  .automation-pill {
    top: 250px !important;
    left: 1350px !important;
  }

  .info-with-image {
    align-self: center;
  }

  .first-info {
    margin-top: 150px;
  }

  .second-info {
    flex-direction: row-reverse;
  }

  .second-info .checked-info {
    margin-left: 100px;
  }

  .automation-fith-image {
    width: 400px;
    height: 400px;
    align-self: center;
    margin-top: 0;
    margin-left: 80%;
  }

  .automation-third-circle {
    left: 30px;
    margin-left: -50px;
    margin-top: 417px;
  }

  .automation-fourth-circle {
    /* right: 10%;
    margin-top: 230px; */
    display: none;
  }

  .automation-third-blurred-circle {
    width: 170px;
    height: 170px;
    position: absolute;
    margin-left: 100px;
    margin-top: 10px;
  }

  .shadowed-section {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }

  .shadowed-section .checked-info {
    margin-left: -100px;
    margin-right: 40px;
  }

  .pill-position {
    top: 100px;
    left: 76%;
  }

  .second-section-items {
    margin-top: 0px;
  }
  .page-container {
    margin-top: 60px;
  }
}
