.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font: normal normal normal 16px/24px Archivo;
}

.functional-circles-container {
    position: relative;
    z-index: 0;
}
.functional-right-circle {
    position: absolute;
    top: 570px;
    right: -280px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 56px solid #21ce99;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}
.functional-left-circle {
    position: absolute;
    width: 220px;
    height: 220px;
    top: 0px;
    left: -400px;
    border-radius: 50%;

    border: 56px solid #27dba419;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}

.functional-header-text-button {
    text-align: left;
    padding: 20px;
}
.functional-header-text-button p {
    line-height: 24px;
}
.functional-titles {
    font: var(--unnamed-font-style-italic) normal
        var(--unnamed-font-weight-normal) 18px/24px
        var(--unnamed-font-family-archivo);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: italic normal normal 18px/24px Archivo;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}
.functional-intermediate-image {
    display: flex;
    flex-direction: column;
    margin: 80px 0;
}
.functional-first-encircled-img {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border: 24px solid #21ce99;
    width: 287px;
    height: 287px;
    border-radius: 287px;
    z-index: 10;
}
.functional-first-blurred-circle {
    width: 100px;
    height: 100px;
    border: 40px solid #27dba419;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 50%;
    margin-top: -100px;
}
.functional-large-image-container {
    width: 249px;
    height: 249px;
    /* background: transparent url('img/linus-mimietz-UzbvepPfb14-unsplash.png') 0% 0% no-repeat padding-box; */
    border-radius: 220px;
    opacity: 1;
}
.last-image {
    display: flex;
    flex-direction: column;
    margin: 120px 0 -90px 0;
}
.functional-third-circle {
    width: 60px;
    height: 60px;
    border-top: 40px solid #21ce99;
    border-right: 40px solid #21ce99;
    border-bottom: 40px solid #21ce99;
    margin-left: -30px;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    margin-top: 295px;
    position: absolute;
}

.functional-third-blurred-circle {
    width: 114px;
    height: 114px;
    border: 56px solid #27dba419;
    border-radius: 50%;
    margin-top: -100px;
}
.functional-fourth-circle {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 24px solid #21ce99;
    border-radius: 50%;
    z-index: 1;
    right: 30px;
    margin-top: 70px;
}
.functional-fith-image {
    width: 300px;
    height: 300px;
    align-self: flex-end;
    margin-bottom: 160px;
    margin-top: -150px;
    z-index: 10;
}
.functional-second-circle-container {
    display: flex;
    margin-top: -300px;
    z-index: 2;
}

.functional-second-circle {
    width: 45px;
    height: 45px;
    border: 32px solid #21ce99;
    border-radius: 287px;
    margin-top: 50px;
    z-index: 5;
}

.functional-second-blurred-circle {
    width: 95px;
    height: 95px;
    border: 40px solid #27dba419;
    border-radius: 50%;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin-left: -30px;
}

.functional-second-image {
    width: 171.5px;
    height: 171.5px;
    align-self: flex-end;
    z-index: 10;
}

.functional-second-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 10;
}

.functional-fourth-image {
    width: 122px;
    height: 122px;
    margin-bottom: 10px;
}

.functional-info-with-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* max-width: 900px; */
}

.functional-checked-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.shadowed-section {
    width: 100% !important;
    max-width: unset !important;
    padding: 20px;
    margin-top: 50px;
    background-color: #aaaaaa19;
}

.functional-qualities-top-section {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    z-index: 1;
}

.functional-pill-position {
    top: -25px;
    left: 150px;
}

.functional-cursive-text {
    width: 323px;
    height: 71px;
    font: var(--unnamed-font-style-italic) normal
        var(--unnamed-font-weight-normal) 18px/24px
        var(--unnamed-font-family-archivo);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: italic normal normal 18px/24px Archivo;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 10;
}

.functional-explanation-header h3 {
    margin-top: 40px;
    font-style: italic;
    font-size: large;
    font-weight: normal;
}

.functional-second-explanation-header h3 {
    margin-top: 100px;
    font-style: italic;
    font-size: large;
    font-weight: normal;
}

.card-subgroup {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .functional-fourth-blurred-circle {
        position: absolute;
        right: 300px;
        /* top: -100px; */
        width: 100px;
        height: 100px;
        border: 40px solid #27dba419;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        border-radius: 50%;
        margin-top: -50px;
    }
    .functional-checked-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 44px;
    }

    .functional-first-blurred-circle {
        width: 200px;
        height: 200px;
        margin-left: -100px;
        margin-top: -200px;
    }

    .functional-fifth-blurred-circle {
        width: 150px;
        height: 150px;
        border: 40px solid #27dba419;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        border-radius: 50%;
        margin-top: -150px;
        margin-left: 200px;
    }

    .functional-right-circle {
        position: absolute;
        top: 400px;
        right: -360px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 56px solid #21ce99;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }
    .functional-left-circle {
        position: absolute;
        width: 500px;
        height: 500px;
        top: 125px;
        left: -700px;
        border-radius: 50%;

        border: 56px solid #27dba419;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }

    .checked-with-icons {
        flex-direction: column;
        column-count: 2;
    }

    .bicolor-titled-container {
        text-align: center;
        max-width: 60%;
        margin-top: 0px;
    }

    .functional-cursive-text {
        width: 452px;
        height: 60px;
    }

    .functional-qualities-top-section {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        column-gap: 100px;
        justify-content: center;
        align-items: center;
        column-count: 2;
    }

    .functional-button.functional {
        align-self: start;
        margin-top: 30px;
    }

    .functional-intermediate-image {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .functional-fith-image {
        width: 400px;
        height: 400px;
        align-self: center;
        margin-top: 30px;
        margin-left: 150px;
    }

    .functional-fourth-circle {
        right: 90px;
        margin-top: 270px;
    }

    .functional-third-blurred-circle {
        width: 170px;
        height: 170px;
        position: absolute;
        /* margin-left: 100px; */
        margin-top: 10px;
    }

    .functional-third-circle {
        left: 30px;
        margin-left: -50px;
        margin-top: 417px;
    }

    .functional-info-with-image {
        align-self: flex-start;
        margin-top: 100px;
    }

    button.functional {
        margin-left: 110px !important;
    }

    .shadowed-section {
        padding: 0;
    }

    .second-info .functional-intermediate-image {
        margin-left: 270px;
    }

    .functional-pill-position {
        top: -30px;
        left: 350px;
    }
    .functional-checked-info-header {
        text-align: left !important;
    }

    .functional-first-encircled-img {
        width: 450px;
        height: 450px;
    }

    .functional-first-encircled-img img {
        width: 420px;
        height: 420px;
    }

    .functional-second-image {
        width: 220px;
        height: 220px;
    }

    .functional-fourth-image {
        width: 160px;
        height: 160px;
    }

    .functional-third-image {
        width: 240px;
        height: 240px;
    }

    .card-subgroup {
        display: flex;
        flex-direction: row;
        width: 70%;
        column-gap: 75px;
    }
}

@media screen and (min-width: 1200px) {
    .functional-fifth-blurred-circle {
        width: 150px;
        height: 150px;
        border: 40px solid #27dba419;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        border-radius: 50%;
        margin-top: -150px;
        margin-left: 200px;
    }

    .functional-second-explanation-header {
        margin-left: 100px;
        font-size: 25px;
    }

    .functional-cursive-text {
        width: 573px;
        height: 60px;
    }

    .functional-qualities-top-section {
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
    }

    .functional-right-circle {
        position: absolute;
        top: 60px;
        right: 30px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 56px solid #21ce99;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }

    .functional-left-circle {
        position: absolute;
        width: 700px;
        height: 700px;
        top: -400px;
        left: -1410px;
        border-radius: 50%;

        border: 100px solid #27dba419;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
    }

    .checked-with-icons {
        flex-direction: column;
        column-count: 2;
    }

    .functional-info-with-image {
        flex-direction: row;
        max-width: unset;
        justify-content: space-around;
        align-self: center;
    }

    .functional-pill {
        top: 250px !important;
        left: 1350px !important;
    }

    .first-info {
        margin-top: 250px;
    }

    .second-info {
        flex-direction: row-reverse;
        margin-top: 0px;
    }

    .functional-checked-info {
        width: auto !important;
    }
    .second-info .functional-checked-info {
        margin-left: 100px;
    }

    .functional-first-encircled-img {
        width: 450px;
        height: 450px;
    }

    .functional-first-encircled-img img {
        width: 420px;
        height: 420px;
    }

    .functional-second-image {
        width: 220px;
        height: 220px;
    }

    .functional-fourth-image {
        width: 160px;
        height: 160px;
    }

    .functional-third-image {
        width: 240px;
        height: 240px;
    }

    .functional-fith-image {
        width: 400px;
        height: 400px;
        align-self: center;
        margin-top: 0;
        margin-left: 80%;
    }

    .functional-third-circle {
        left: 20px;
        margin-left: -50px;
        margin-top: 417px;
    }

    .functional-fourth-circle {
        /* right: 50px;
    margin-top: 320px;
    width: 40px;
    height: 40px; */
        display: none;
    }

    .functional-third-blurred-circle {
        width: 170px;
        height: 170px;
        position: absolute;
        margin-left: 170px;
        margin-top: -30px;
    }

    .shadowed-section {
        padding: 20px;
    }

    .shadowed-section {
        align-items: center;
        justify-content: center;
    }

    .shadowed-section .functional-checked-info {
        margin-left: 64px;
        margin-right: 40px;
    }

    .functional-pill-position {
        /* top: 250px;
    left: 1150px; */

        margin-left: 70%;
        margin-right: 70%;
    }
    .functional-checked-info-header {
        text-align: left !important;
    }

    .card-subgroup {
        display: flex;
        flex-direction: row;
        width: 46%;
    }

    .achievement-card {
        margin: 0 4%;
    }
}
