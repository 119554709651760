.form-group {
  margin-top: 1.5em;
}

.form-input {
  /* width: 250px; */
  width: 100%;
  height: 2em;
  margin-top: 0.5em;
  padding: 1px 0;
  border: 1px solid #5a5959;
  border-radius: 0.25rem;
  color: #000;
  background-color: white;
  /* color: #7c7c7c; */
  font: inherit;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textarea-class {
  height: 7.8em;
}

.recaptcha {
  padding-top: inherit;
}

.form-button {
  text-align: center;
}

.error-message {
  color: #de4330;
  margin-bottom: 2em;
  /* width: 250px; */
}

.error {
  border-bottom: #de4330 thin solid;
  /* width: 250px; */
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .form-inputs {
    display: flex;
    column-gap: 1.5rem;
    /* width: 296px; */
  }
  /* .error-message, .error { */
  .form-left-section,
  .form-right-section,
  .recaptcha {
    width: 303px;
  }

  .form-button {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .form-inputs {
    display: flex;
    column-gap: 1.5rem;
    /* width: 296px; */
  }

  /* .error-message, */
  /* .error { */
  /* width: 296px; */
  /* } */

  .form-button {
    text-align: left;
  }

  .form-group {
    margin-top: 1.5em;
  }

  .form-input {
    /* width: 235px; */
    height: 2em;
    border: 1px solid #7c7c7c;
    border-radius: 0.25rem;
    /* color: #7c7c7c !important; */
    font: inherit;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-group label {
    width: fit-content;
    margin-top: 3em;
    display: block;
  }

  .textarea-class {
    height: 8.8em;
    /* height: 141px */
  }

  .required {
    font-style: italic;
  }

  .recaptcha {
    padding-top: 20px;
  }

  .form-left-section,
  .form-right-section {
    width: 250px;
  }

  .recaptcha {
    width: 303px;
  }
}
