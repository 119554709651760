.vertical-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 87%;
  margin: 15px auto;
  padding: 30px 20px;
  /* box-shadow: 0px 3px 40px #AAAAAA29; */
  box-shadow: 0 20px 33px 0 #0000004d;
  border-radius: 0.312rem;
}

.vertical-card-title {
  text-align: center;
  font: normal normal bold 18px/24px Archivo;
  margin-bottom: 20px;
}

.vertical-card-container-button {
  margin-top: auto;
}

.vertical-card-button {
  color: white;
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .vertical-card-title {
    height: 48px;
  }

  /* .vertical-card .card-content {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  /* .vertical-card { */
  /* width: 30%; */
  /* margin: 0 25px; */
  /* } */

  /* .vertical-card .card-content {
    -webkit-line-clamp: 7;
  } */
}

/* @media screen and (min-width: 1092px) { */
  /* .vertical-card .card-content {
    -webkit-line-clamp: 6;
  } */
/* } */

@media screen and (min-width: 1594px) {
  .vertical-card-title {
    height: auto;
  }
}
