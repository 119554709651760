.footer {
  padding: 6em 36px 1em;
  /* padding-top: 6em; */
  color: #000;
  background-color: #ffffff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-copyright {
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  text-align: center;
}

/* .footer-top-section {
} */
/* .footer-section {
} */

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .footer-top-section {
    display: flex;
    column-gap: 64px;
  }
  /* .footer { */
  /* padding: 80px; */
  /* } */
  .footer-copyright {
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .footer-top-section {
    display: flex;
    flex-direction: row;
    column-gap: 64px;
  }
  .footer {
    display: flex;
    padding: 6em 0px 1em 0px;
  }
  .footer-section {
    display: flex;
    column-gap: 64px;
  }
}
