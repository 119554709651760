.blog-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #21ce99;
}
.preview-section {
  width: 100%;
  background-color: black;
  margin-top: 100px;
  z-index: 2;
}
.preview-section a {
  color: white;
}
.blogs-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding: 20px;
}

.a11y-blog-presentation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
  width: 80%;
}

.filters-section {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}
.filters-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}

.bottom-divider-container {
  position: relative;
  height: 55px;
  width: 100%;
}

.blog-preview-bottom-divider {
  position: absolute;
  height: 55px;
  width: 100%;
  bottom: -1px;
  background-size: auto 55px;
}
.blog-preview-top-divider {
  position: absolute;
  height: 55px;
  width: 100%;
  top: -1px;
  background-size: auto 55px;
  transform: rotate(180deg);
}
.blog-presentation-image {
  border-radius: 50%;
}
.home-page-ilustration-container {
  display: none;
}
.blog-button {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid white;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .blogs-preview-container {
    padding: 20px;
    display: grid;
    justify-content: center;
    justify-self: center;

    gap: 45px; /* Optional spacing between items */
    margin-top: 50px;
    width: 70%;
    grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
  }

  .filters-container {
    width: 70%;
    display: flex;
    justify-content: start;
    padding: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .blogs-preview-container {
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  }
  .home-page-ilustration-container {
    display: inherit;
  }
  .a11y-blog-presentation-container {
    flex-direction: row;
  }
  .filters-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 20px;
  }
}

.read-blog-page {
  width: 100%;
  background-color: black !important;
  color: white;
  display: flex;
  justify-content: center;
}
.read-blog-content {
  text-align: start;
  width: 60% !important;
}
.read-blog-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font: inherit;
}
.article-img-preview {
  width: 100%;
  height: 500px !important;
  border-radius: 10px;
  margin-top: 50px;
}
.article-img-preview img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
