.service-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-card {
  width: 100px;
  height: 96px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 3px 40px #aaaaaa19;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-card-content h3 {
  font: normal normal bold 18px/32px Archivo !important;
}
.service-card-text {
  line-height: 24px;
}
.service-card-text p {
  margin: 0px;
}
.service-card-img {
  width: 45px;
  height: 45px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .service-card-container {
    flex-direction: row;
    column-gap: 38px;
    width: 608px;
  }
  .service-card {
    height: 140px;
    width: 140px;
    margin-bottom: 15px;
  }
  .service-card-content {
    text-align: start;
    align-items: flex-start;
  }
  .service-card-img {
    width: 65px;
    height: 65px;
  }
}

@media (min-width: 1200px) {
  .service-card-img {
    width: 70px;
    height: 70px;
  }
  .service-card {
    height: 140px;
    width: 140px;
    margin-bottom: 15px;
  }
}
