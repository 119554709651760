.clients-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin-top: 60px;*/
  padding-bottom: 7rem;
  position: relative;
  font: normal normal 900 32px/40px Archivo;
}

.bicolor-titled-container {
  width: auto !important;
  text-align: center !important;
}

.clients-bicolored-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .clients-container {
    align-items: flex-start;
    text-align: start;
    font: normal normal 900 40px/44px Archivo;
  }

  .clients-bicolored-text {
    align-self: center;
  }
}

@media (min-width: 1200px) {
  .clients-container {
    align-items: center;
    text-align: center;
    font: normal normal 900 40px/44px Archivo;
  }

  .clients-bicolored-text {
    align-self: center;
  }
}