.footer-links {
  list-style-type: none;
  padding-left: 0px;
}
.footer-nav ul li {
  padding: 1em 0;
}
.footer-link {
  text-decoration: none;
  color: black;
}
.a11y-button.footer-link {
  color: #000;
}
.footer-link:hover {
  /* outline: 5px solid yellow; */
  outline: 2px solid #ffffff;
  box-shadow: 0 0 0 3px #000000;
  outline-offset: 2px;
}
